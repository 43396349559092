<template>
  <section id="one-on-one-list">
    <loader v-if="loading" :loading-message="loadingMessage" />

    <!-- My Requests -->
    <list-one-on-one-list
      :requests="requestedItems"
      :show-empty-message="false"
      @select-item="select($event.selected, $event.index, requested)"
      list-title="My Requests"
      show-legend
    >
      <template v-slot:empty v-if="requested.length === 0">
        <p class="list-item list-item--label">
          <em>
            <span>You have not schedule any 1-on-1s: request one</span>&nbsp;
            <router-link :to="{ name: 'CreateOneOnOne' }">here</router-link>
          </em>
        </p>
      </template>
    </list-one-on-one-list>

    <hr
      :class="{
        divider: true,
        'divider--md': requestedItems.length,
        'divider--lg grey-light': !requestedItems.length
      }"
    />

    <!-- Assigned to Current User (Teachers) -->
    <list-one-on-one-list
      :requests="assignedItems"
      :show-empty-message="false"
      @select-item="select($event.selected, $event.index, assigned)"
      list-title="Assigned Requests"
      show-legend
      v-if="!isStudentUser && !isScheduleAdminUser"
    >
      <template v-slot:empty v-if="assignedItems.length === 0">
        <p class="list-item list-item--label">
          <em class="wide">
            Nothing assigned: looks like I'm free for some quality time!
          </em>
        </p>
      </template>
    </list-one-on-one-list>

    <!-- Modal for selected 1-on-1 -->
    <modal-component
      :title="form.selected && form.selected.name"
      :visible="Boolean(form.selected)"
      @close="closeModal"
    >
      <hr class="divider divider--md grey-light" />

      <div v-if="!showZoomModal">
        <!-- selected item -->
        <list-one-on-one-selected
          class="slide-down-fade-in"
          :selected="form.selected"
          :teacher-swapped="form.updateInstructor"
          @join-meeting="attemptJoinMeeting"
        />

        <!-- Confirm 'Decline' action -->
        <template v-if="decliningAppointment">
          <p class="slide-up-fade-in">
            <i class="fas fa-exclamation-triangle accent--text"></i>
            &nbsp;<b class="error--text"> Are you sure you wish to decline? </b>
          </p>

          <p>
            We understand that life happens. If you would like to attend a
            similar session in the future, just request a new one.
          </p>

          <hr class="divider divider--md grey-light" />

          <p class="slide-up-fade-in" v-if="form.selected">
            <b class="error--text">Decline</b> this
            <b>{{ form.selected.name }}</b> appointment?
          </p>
        </template>

        <!-- Confirm/Decline Controls -->
        <template v-if="selectedIsApproved && isRequestingUser">
          <product-form
            v-if="requiresDonation && !decliningAppointment"
            type="One-on-One"
            @payment-complete="confirmAppointment"
          />
          <div class="form--row">
            <!-- Decline Appt -->
            <button
              class="outline error--text wide"
              :class="{ 'slide-up-fade-in': decliningAppointment }"
              @click.stop="attemptDeclineAppt"
            >
              <i
                class="fas"
                :class="{
                  'fa-ban': !decliningAppointment,
                  'fa-arrow-left': decliningAppointment
                }"
              />
              {{ `${decliningAppointment ? "Back" : "I'm not going"}` }}
            </button>

            <!-- Confirm Appt -->
            <button
              class="success wide"
              v-if="!decliningAppointment && !form.selected.approvalIsExpired"
              @click.stop="confirmAppointment"
            >
              <i class="fas fa-check-circle"></i>
              {{ loadingMessage || "Confirm" }}
            </button>

            <!-- Confirm Decline Action -->
            <button
              class="button error slide-up-fade-in wide"
              @click.stop="declineAppointment"
              v-if="decliningAppointment"
            >
              <i class="fas fa-check-circle"></i>
              Decline Appointment
            </button>
          </div>
        </template>
      </div>

      <template v-else>
        <p class="slide-up-fade-in">
          You are about to {{ showJoinZoomModal ? "join" : "start" }}
          <b>{{ form.selected.name }}</b> via (an external)
          <b>Zoom</b> application.
          <span v-if="activeUser">
            <template v-if="showJoinZoomModal">
              To ensure that the instructor recognizes and admits you, please
            </template>
            <template v-if="showStartZoomModal">
              Please ensure that you
            </template>
            <b>
              sign into zoom with your
              <span class="accent--text">{{ appOrgName }}</span> email address
            </b>
            (
            <b class="grey--text">{{ activeUser.email }}</b>
            ).
          </span>
        </p>

        <hr class="divider divider-" />

        <p class="slide-up-fade-in">
          <b class="success--text">Ready to continue?</b>
          This will temporarily take you out of the
          <b class="accent--text">{{ appOrgName }}</b> application.
        </p>

        <!-- Join Mtg -->
        <button class="success wide" @click.stop="joinMeeting">
          <i class="fas fa-check-circle"></i>
          {{ isAssignedUser ? "Start" : "Join" }}
        </button>
      </template>
    </modal-component>
  </section>
</template>

<script>
import {
  confirmOneOnOne,
  createOrUpdateOneOnOne,
  listOneOnOnes
} from "../models/oneOnOne.js";
import { oneOnOneStatuses } from "../helpers/one-on-one.helpers";
import state from "../state";
import FormsMixin from "./mixins/forms.mixin";
import ListOneOnOneList from "./ListOneOnOne.List.vue";
import ListOneOnOneSelected from "./ListOneOnOne.Selected";
import Loader from "./Loader.vue";
import ModalComponent from "./ModalComponent.vue";
import ProductForm from "./ProductForm.vue";
import OneOnOneMixin from "./mixins/one-on-one.mixin";

export default {
  name: "ListOneOnOne",

  mixins: [FormsMixin, OneOnOneMixin],

  components: {
    ListOneOnOneList,
    ListOneOnOneSelected,
    Loader,
    ModalComponent,
    ProductForm
  },

  data: () => ({
    assigned: [],
    decliningAppointment: false,
    form: { selected: null, sIndex: -1, src: null },
    requested: [],
    requiredFields: [],
    showJoinZoomModal: false,
    showStartZoomModal: false
  }),

  computed: {
    assignedItems() {
      const { assigned } = this;
      const matchStatus = r => r.approvalStatus === oneOnOneStatuses.CONFIRMED;
      return assigned.length === 0 ? assigned : assigned.filter(matchStatus);
    },

    oneOnOne() {
      return this.form.selected || {};
    },

    requestedItems() {
      const { requested } = this;
      const matchStatus = r => r.approvalStatus !== oneOnOneStatuses.COMPLETE;
      return requested.length === 0 ? requested : requested.filter(matchStatus);
    },

    requiresDonation() {
      const { selected } = this.form;
      if (!selected) return false;
      return this.selectedIsApproved && selected.requiresDonation;
    },

    selectedIsApproved() {
      const { selected } = this.form;
      return (selected || {}).approvalStatus === oneOnOneStatuses.APPROVED;
    },

    showModal() {
      return Boolean(this.form.selected) || this.showZoomModal;
    },

    showZoomModal() {
      return this.showJoinZoomModal || this.showStartZoomModal;
    },

    zoomLink() {
      const { selected } = this.form;
      if (!selected) return null;
      return this.isAssignedUser ? selected.zoomStartLink : selected.zoomLink;
    }
  },

  async mounted() {
    if (!this.activeUser) return;

    const { activeUser, isTeacherUser } = this;
    const assignedList = isTeacherUser
      ? listOneOnOnes({ assignedTeacher: activeUser.id })
      : Promise.resolve([]);
    const [assigned, requested] = await Promise.all([
      assignedList,
      listOneOnOnes({ requestedBy: activeUser.id })
    ]);

    this.requested = requested;
    this.assigned = assigned;
  },

  methods: {
    async attemptDeclineAppt() {
      if (this.form.selected.approvalIsExpired) {
        return await this.declineAppointment();
      }
      this.decliningAppointment = !this.decliningAppointment;
      return Promise.resolve(true);
    },

    attemptJoinMeeting() {
      this.showJoinZoomModal = false;
      this.showStartZoomModal = false;
    },

    cancelJoinMeeting() {
      this.showJoinZoomModal = this.isRequestingUser;
      this.showStartZoomModal = !this.isRequestingUser;
    },

    select(selected, sIndex = -1, src = null) {
      return this.appendFormData({
        selected,
        sIndex,
        src,
        updateInstructor: false
      });
    },

    closeModal() {
      // close modal
      this.decliningAppointment = false;
      this.select(null);
      this.stopLoading();
      return state.multiple({ activeProductId: null, showProductModal: false });
    },

    async confirmAppointment() {
      this.startLoading("Confirming appointment ...");
      const { selected } = this.form;
      const { zoomUserId } = selected.assignedTeacher;
      const params = { ...selected, ...this.form, zoomUserId };
      await confirmOneOnOne(params);

      // update requests list
      this.requested = await listOneOnOnes({ requestedBy: this.activeUser.id });
      return this.closeModal();
    },

    async declineAppointment() {
      this.startLoading("Declining appointment ...");
      const status = { approvalStatus: oneOnOneStatuses.DECLINED };
      const params = Object.assign({}, this.form.selected, status);
      await createOrUpdateOneOnOne(params, this.form.selected);

      // update requests list
      this.requested = await listOneOnOnes({ requestedBy: this.activeUser.id });
      return this.closeModal();
    },

    joinMeeting() {
      window.open(this.zoomLink);
      this.closeModal();
    }
  }
};
</script>
