<template>
  <section id="one-on-one--selected">
    <!-- Selected one-on-one overview -->
    <approve-one-on-one-overview
      hide-teacher-availability
      :one-on-one="selected"
      :teacher-swapped="teacherSwapped"
    />

    <!-- CATEGORY AND DESCR -->
    <div v-if="isPending" class="category-description">
      <h6 class="category-description__title">{{ selectedCategory.name }}</h6>
      <p>{{ selectedCategoryDesc }}</p>
    </div>

    <!-- Upcoming date -->
    <p class="list-item" style="padding:0" v-if="selected.isUpcoming">
      <em class="h6 grey--text wide w--centered">
        Begins {{ selected.beginsToRelative }}
      </em>
    </p>

    <!-- Past date -->
    <p class="list-item" style="padding:0" v-if="selected.hasEnded">
      <span class="wide w--centered error--text" style="text-align:center">
        <i class="fas fa-info-circle" />&nbsp;
        <b class="h6 error--text">Expired event</b>
      </span>
    </p>

    <!-- Join/start meeting -->
    <button
      v-if="canStart && isConfirmed && selected.zoomMeetingId"
      class="success wide"
      @click.stop="$emit('join-meeting')"
    >
      Join Meeting
    </button>
  </section>
</template>

<script>
import {
  categoryDescriptionFor,
  categoryFor
} from "../helpers/one-on-one.helpers";
import OneOnOneMixin from "./mixins/one-on-one.mixin";
import ApproveOneOnOneOverview from "./ApproveOneOnOne.Overview.vue";

export default {
  name: "ListOneOnOneSelected",

  mixins: [OneOnOneMixin],

  props: {
    selected: { type: Object, required: true },
    teacherSwapped: Boolean
  },

  components: { ApproveOneOnOneOverview },

  computed: {
    oneOnOne() {
      return this.selected;
    },

    requiresDonation() {
      const { selected } = this;
      return this.isApproved && selected.requiresDonation;
    },

    selectedCategory() {
      return categoryFor(this.selected);
    },

    selectedCategoryDesc() {
      return categoryDescriptionFor(this.selected);
    }
  }
};
</script>

<style lang="scss">
#one-on-one--selected {
  .list-item--one-on-one {
    cursor: default;

    &:hover {
      outline: none;
    }
  }
}
</style>
